body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#trackCanvas {
  display: flex;
  border: solid 3px lightgrey;
  box-shadow: 5px 5px 5px 1px #888888;
  margin: 0 auto;
  margin-left: 25px;
  width: 100%;
}

.render-layer1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.render-layer2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.top-row {
  display: grid;
  justify-items: center;
  grid-template-columns: 100%;
  grid-column-gap: 0;
  grid-row-gap: 0.5rem;
}

@media only screen and (min-width: 1000px) {
  .top-row {
    grid-template-columns: 30% 70%;
  }
}

.data-tables-row {
  display: grid;
  justify-items: center;
  grid-template-columns: 100%;
  grid-column-gap: 0;
  grid-row-gap: 0.5rem;
}

.table {
  width: 100%;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 900px) {
  .data-tables-row {
    grid-template-columns: 47.5% 47.5%;
    grid-column-gap: 5%;
  }
}

.fixed-header {
  width: 100%;
  table-layout: collapse;
}

.fixed-header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 400px;
}

.fixed-header thead tr {
  display: block;
  width: 100%;
}

.fixed-header th,
.fixed-header td {
  text-align: center;
  width: 100px;
}

.renderCanvases {
}

.seeking-container {
}

.livetelembutton {
  border-radius: 0.7rem;
}

.render-container {
  align-items: center;
  min-width: 520px;
  min-height: 520px;
}

.top-row {
  min-height: 520px;
}

.telemetry-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
}

.bottom-panel {
  display: flex;
}

.menu-bar {
  display: flex;
  justify-content: space-between;
}

.telemetry-card {
  width: 200px;
  font-size: 1.5rem;
  margin-inline: auto;
  margin-top: 0.5em;
}

/* Gauges */

.gauge-holder {
  width: 90%;
  margin: auto;
  margin-top: 3%;
}

.gauge {
  width: 100%;
  max-width: 250px;
  color: #009578;
  font-size: 150%;
}

.gauge-body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge-fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  background: #009578;
  transform-origin: center top;
  transform: rotate(0.25turn);
  transition: transform 0.2s ease-out;
}

.gauge-cover {
  width: 75%;
  height: 150%;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #b4c0be; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 1; /* Set transparency (for mouse-over effects on hover) */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  border-radius: 25%;
  background: #009578; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  border-radius: 25%;
  background: #009578; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.footer-links {
  text-decoration: none;
  color: black;
}

.footer-links:hover {
  color: black;
  text-decoration: underline;
}

.fullscreen-modal .modal-content {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  border-radius: 0;
  border: none;
}

.fullscreen-modal .modal-body {
  padding: 0;
}

.fullscreen-modal .card {
  padding: 1rem;
  top: 10%;
  min-width: 350px;
  max-width: 450px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-file {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.underline {
  float: left;
  display: block;
  color: black;
  text-align: center;

  text-decoration: none;
}
