body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: 0.4rem;
  border-radius: 2px;
  width: 100%;
  height: 1.5rem;
}

progress[value]::-webkit-progress-bar {
  background-color: #b4c0be;
}

progress[value]::-webkit-progress-value {
  background-color: #009578;
}

html {
  scroll-behavior: smooth;
}

body {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}
